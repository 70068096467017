// 考勤-出勤
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 出勤-管理-每日出勤
// 每日出勤列表
export async function getAttendancePage(params) {
  return request(`${ATTENDACE}/dailyAttendance/page`, METHOD.GET, params)
}
// 刷新每日统计出勤结果
export async function refreshPunchApi(params) {
  return request(`${ATTENDACE}/dailyAttendance/refreshPunch`, METHOD.GET, params)
}

// 人员每日出勤详情
export async function getDailAttendance(params) {
  return request(`${ATTENDACE}/dailyAttendance/getDailAttendance`, METHOD.GET, params)
}

// 每日出勤列表导出
export async function getAttendanceResultList(params) {
  return request(`${ATTENDACE}/dailyAttendance/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 出勤-管理-月度汇总

// 查询月度汇总列表
export async function getMonthAttendancePageList(params) {
  return request(`${ATTENDACE}/monthAttendance/pageList`, METHOD.GET, params)
}
// 下载月度考勤数据批量导入模板
export async function getImportTemplate(params) {
  return request(`${ATTENDACE}/monthAttendance/getImportTemplate`, METHOD.GET, params)
}
// 导出月度汇总列表
export async function exportMonthAttendance(params) {
  return request(`${ATTENDACE}/monthAttendance/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 导入月度考勤数据
export async function importMonthAttendance(params) {
  return request(`${ATTENDACE}/monthAttendance/batchImport`, METHOD.POST, params)
}
// 查询考勤规则
export async function getAttendanceRule(params) {
  return request(`${ATTENDACE}/monthAttendance/getAttendanceRule`, METHOD.GET, params)
}
// 编辑月度考勤信息
export async function editMonthAttendanceUpdate(params) {
  return request(`${ATTENDACE}/monthAttendance/update`, METHOD.POST, params)
}
// 删除月度考勤信息
export async function deleteMonthAttendance(params) {
  return request(`${ATTENDACE}/monthAttendance/deleteList`, METHOD.POST, params)
}
// 查询表头配置
export async function getResultItem(params) {
  return request(`${ATTENDACE}/monthAttendance/resultItem/getResultItem/`, METHOD.GET, params)
}
// 保存表头配置
export async function saveResultItem(params) {
  return request(`${ATTENDACE}/monthAttendance/resultItem/saveResultItem`, METHOD.POST, params)
}
// 月度详情-考勤tab项表头
export async function getDetailTab(params) {
  return request(`${ATTENDACE}/monthAttendance/getDetailTab`, METHOD.GET, params)
}
// 月度详情-查询人员月度考勤详情
export async function getDetail(params) {
  return request(`${ATTENDACE}/monthAttendance/getDetail`, METHOD.GET, params)
}
// 发送考勤确认
export async function confirm(params) {
  return request(`${ATTENDACE}/monthAttendance/confirm`, METHOD.POST, params)
}
// 人员模糊查询
export async function getStaffsVague(params) {
  return request(`/hrsaas-base/staff/getStaffsVague_1652060653908`, METHOD.GET, params)
}
// 人员模糊查询
export async function ifSendConfirm(params) {
  return request(`${ATTENDACE}/monthAttendance/ifSendConfirm`, METHOD.GET, params)
}

// 出勤-管理-月度关注

// 查询月度关注列表
export async function getEmphasPageList(params) {
  return request(`${ATTENDACE}/emphas/pageList`, METHOD.GET, params)
}
// 导出月度关注列表
export async function exportEmphas(params) {
  return request(`${ATTENDACE}/emphas/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 月度关注补卡相关的接口调用   出勤-记录-补卡   中相关接口

// 出勤-记录-打卡

// 分页查询打卡数据
export async function getstaffClockPageList(params) {
  return request(`${ATTENDACE}/clock/getPageList`, METHOD.GET, params)
}
// 编辑打卡时间
export async function editStaffClockTime(params) {
  return request(`${ATTENDACE}/clock/editStaffClockTime`, METHOD.POST, params)
}
// 添加打卡记录-单个添加
export async function addStaffClock(params) {
  return request(`${ATTENDACE}/clock/addStaffClock`, METHOD.POST, params)
}
// 删除员工打卡记录
export async function delStaffClock(params) {
  return request(`${ATTENDACE}/clock/delStaffClock`, METHOD.POST, params)
}
// 导出员工打卡记录
export async function exportStaffClock(params) {
  return request(`${ATTENDACE}/clock/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 出勤-记录-补卡

// 补卡记录列表
export async function getStaffApplyAppendPunch(params) {
  return request(`${ATTENDACE}/applyAppendPunch/page`, METHOD.GET, params)
}
// hr添加补卡记录
export async function addStaffApplyAppendPunch(params) {
  return request(`${ATTENDACE}/applyAppendPunch/create`, METHOD.POST, params)
}
// hr编辑补卡记录
export async function editStaffApplyAppendPunch(params) {
  return request(`${ATTENDACE}/applyAppendPunch/update`, METHOD.POST, params)
}
// hr删除补卡申请记录
export async function delSstaffApplyAppendPunch(params) {
  return request(`${ATTENDACE}/applyAppendPunch/delete`, METHOD.POST, params)
}
// 删除补卡前校验是否参与核计薪资
export async function checkStaffApplyAppendPunch(params) {
  return request(`${ATTENDACE}/applyAppendPunch/check`, METHOD.POST, params)
}
// 缺卡日期目录
export async function missCardDate(params) {
  return request(`${ATTENDACE}/applyAppendPunch/missCardDatePage`, METHOD.GET, params)
}
// 缺卡记录列表
export async function missCardInfo(params) {
  return request(`${ATTENDACE}/applyAppendPunch/missCardInfoPage`, METHOD.GET, params)
}
// hr导出补卡记录
export async function exportStaffApplyAppendPunch(params) {
  return request(`${ATTENDACE}/applyAppendPunch/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 补卡前获取班次打卡记录或加班记录
export async function getAppendPunchInfoApi(params) {
  return request(`${ATTENDACE}/applyAppendPunch/getAppendPunchInfo`, METHOD.GET, params)
}

// 补卡前获取班次打卡记录或加班记录
export async function createV2Api(params) {
  return request(`${ATTENDACE}/applyAppendPunch/createV2`, METHOD.POST, params)
}

// 打卡时段列表
export async function getPunchListApi(params) {
  return request(`${ATTENDACE}/applyAppendPunch/getPunchList`, METHOD.GET, params)
}
// 出勤-记录-请假

// 分页查询请假信息列表
export async function getapplyVacationPageList(params) {
  return request(`${ATTENDACE}/applyVacation/pageList`, METHOD.GET, params)
}
// hr添加请假记录
export async function addApplyVacationCreate(params) {
  return request(`${ATTENDACE}/applyVacation/create`, METHOD.POST, params)
}
// hr编辑请假记录
export async function editApplyVacationUpdate(params) {
  return request(`${ATTENDACE}/applyVacation/update`, METHOD.POST, params)
}
// hr删除请假记录
export async function delApplyVacation(params) {
  return request(`${ATTENDACE}/applyVacation/delete`, METHOD.POST, params)
}

// hr编辑请假记录
export async function applyVacationDetailApi(params) {
  return request(`${ATTENDACE}/applyVacation/detail`, METHOD.GET, params)
}

// 获取涉及班次，计算请假时长
export async function getApplyVacationCalc(params) {
  return request(`${ATTENDACE}/applyVacation/calc`, METHOD.GET, params)
}
// hr导出请假记录
export async function exportApplyVacation(params) {
  return request(`${ATTENDACE}/applyVacation/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 出勤-记录-外出

// 外出记录列表
export async function getApplyOutsidePunch(params) {
  return request(`${ATTENDACE}/applyOutside/page`, METHOD.GET, params)
}
// hr添加外出记录
export async function addApplyOutsidePunch(params) {
  return request(`${ATTENDACE}/applyOutside/create`, METHOD.POST, params)
}
// hr编辑外出记录
export async function editApplyOutsidePunch(params) {
  return request(`${ATTENDACE}/applyOutside/update`, METHOD.POST, params)
}
// hr外出申请时间单位
export async function getApplyOutsidePunchtime(params) {
  return request(`${ATTENDACE}/applyOutside/getApplyUnit`, METHOD.GET, params)
}
// hr外出申请时长和班次
export async function getApplyOutsidePunchclass(params) {
  return request(`${ATTENDACE}/applyOutside/getShiftAndTime`, METHOD.GET, params)
}
// hr删除外出申请记录
export async function delApplyOutsidePunch(params) {
  return request(`${ATTENDACE}/applyOutside/delete`, METHOD.POST, params)
}
// hr导出外出记录
export async function exportApplyOutsidePunch(params) {
  return request(`${ATTENDACE}/applyOutside/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 出勤-记录-出差

// 出差记录列表
export async function getStaffApplyTravel(params) {
  return request(`${ATTENDACE}/applyTravel/page`, METHOD.GET, params)
}
// hr添加出差记录
export async function addStaffApplyTravel(params) {
  return request(`${ATTENDACE}/applyTravel/create`, METHOD.POST, params)
}
// hr编辑出差记录
export async function editStaffApplyTravel(params) {
  return request(`${ATTENDACE}/applyTravel/update`, METHOD.POST, params)
}
// hr申请出差时间单位
export async function getStaffApplyTravelTime(params) {
  return request(`${ATTENDACE}/applyTravel/getApplyUnit`, METHOD.GET, params)
}
// hr申请出差班次、时长
export async function getStaffApplyTravelClass(params) {
  return request(`${ATTENDACE}/applyTravel/getShiftAndTime`, METHOD.GET, params)
}
// hr删除出差记录
export async function delStaffApplyTravel(params) {
  return request(`${ATTENDACE}/applyTravel/delete`, METHOD.POST, params)
}
// hr导出出差记录
export async function exportStaffApplyTravel(params) {
  return request(`${ATTENDACE}/applyTravel/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 出勤-记录-加班

// 加班记录列表
export async function getStaffApplyOvertime(params) {
  return request(`${ATTENDACE}/applyOvertime/page`, METHOD.GET, params)
}
// hr添加加班记录
export async function addStaffApplyOvertime(params) {
  return request(`${ATTENDACE}/applyOvertime/create`, METHOD.POST, params)
}
// hr编辑加班记录
export async function editStaffApplyOvertime(params) {
  return request(`${ATTENDACE}/applyOvertime/update`, METHOD.POST, params)
}
// hr删除加班记录
export async function delStaffApplyOvertime(params) {
  return request(`${ATTENDACE}/applyOvertime/delete`, METHOD.POST, params)
}
// 导出加班记录
export async function exportStaffApplyOvertime(params) {
  return request(`${ATTENDACE}/applyOvertime/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 加班申请时长、班次、加班类型、补偿方式
export async function getShiftAndTimeApi(params) {
  return request(`${ATTENDACE}/applyOvertime/getShiftAndTime`, METHOD.GET, params)
}

// 考勤-出勤-报表

// 报表下载
export async function downloadReportForm(params) {
  return request(`${ATTENDACE}/reportForm/attendance/download`, METHOD.GET, params)
}
// 报表预览
export async function previewReportForm(params) {
  return request(`${ATTENDACE}/reportForm/attendance/preview`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: false
    }
  })
}

// 单个人员添加到考勤组
export async function addStaffInAttendanceGroup(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/addStaffInAttendanceGroup`, METHOD.POST, params)
}

