import {
  USER,
  LOGIN,
  MESSAGE_SETTING
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 获取证明模板列表
export const getProveTempList = params => request(`${USER}/proveTemp/getProveTempList`, METHOD.GET, params, {
  loading: true
})

// 证明模板状态修改
export const changeTempStatus = params => request(`${USER}/proveTemp/changeTempStatus`, METHOD.POST, params, {
  loading: true
})

// 删除证明模板
export const deleteProveTemp = params => request(`${USER}/proveTemp/deleteProveTemp`, METHOD.POST, params, {
  loading: true
})

// 新增证明模板
export const createProveTemp = params => request(`${USER}/proveTemp/createProveTemp`, METHOD.POST, params, {
  loading: true
})

// 修改证明模板
export const updateProveTemp = params => request(`${USER}/proveTemp/updateProveTemp`, METHOD.POST, params, {
  loading: true
})

/* ----------------------------------试用期设置-----------------------------------------------*/
// 试用期设置
export async function setProbation(params) {
  return request(`${USER}/probation/probationConfig`, METHOD.POST, params)
}
// 获取试用期配置数据
export async function getProbationConfig(params) {
  return request(`${USER}/probation/getProbationConfig`, METHOD.GET, params)
}
/* ----------------------------------工号规则-----------------------------------------------*/
// 获取工号规则
export async function getStaffNumConfig(params) {
  return request(`${USER}/staffNum/getStaffNumConfig`, METHOD.GET, params)
}

// 设置工号自动配置开关
export async function changeAutoGenerate(params) {
  return request(`${USER}/staffNum/changeAutoGenerate`, METHOD.POST, params)
}

// 设置工号规则
export async function staffNumConfig(params) {
  return request(`${USER}/staffNum/staffNumConfig`, METHOD.POST, params)
}

/* ----------------------------------邮箱-----------------------------------------------*/
// 获取邮箱列表
export async function getList(params) {
  return request(`${USER}/tenantEmail/getList`, METHOD.GET, params)
}

// 校验邮箱
export async function checkEmail(params) {
  return request(`${USER}/tenantEmail/checkEmail`, METHOD.POST, params)
}

// 添加邮箱
export async function createTenantEmail(params) {
  return request(`${USER}/tenantEmail/createTenantEmail`, METHOD.POST, params)
}

// 更新邮箱
export async function updateTenantEmail(params) {
  return request(`${USER}/tenantEmail/updateTenantEmail`, METHOD.POST, params)
}

// 删除邮箱
export async function deleteTenantEmail(params) {
  return request(`${USER}/tenantEmail/deleteTenantEmail`, METHOD.POST, params)
}

// 设置默认邮箱
export async function setDefaultEmail(params) {
  return request(`${USER}/tenantEmail/setDefaultEmail`, METHOD.POST, params)
}

/* ----------------------------------入职信息-----------------------------------------------*/
// 入职事项配置列表
export async function getEntryMatterList(params) {
  return request(`${USER}/entryInfo/getEntryMatterList`, METHOD.GET, params)
}

// 入职引导配置列表
export async function getEntryGuideList(params) {
  return request(`${USER}/entryInfo/getEntryGuideList`, METHOD.GET, params)
}

// 入职待办事项列表
export async function getEntryTodoList(params) {
  return request(`${USER}/entryInfo/getEntryTodoList`, METHOD.GET, params)
}

// 更新配置项的状态或内容
export async function updateConfigContent(params) {
  return request(`${USER}/entryInfo/updateConfigContent`, METHOD.POST, params)
}

// 新增入职待办
export async function createEntryTodo(params) {
  return request(`${USER}/entryInfo/createEntryTodo`, METHOD.POST, params)
}

// 更新入职待办
export async function updateEntryTodo(params) {
  return request(`${USER}/entryInfo/updateEntryTodo`, METHOD.POST, params)
}

// 删除入职待办
export async function deleteEntryTodo(params) {
  return request(`${USER}/entryInfo/deleteEntryTodo`, METHOD.POST, params)
}
/* ----------------------------------消息设置-----------------------------------------------*/
// 获取租户消息分组列表
export async function getMessageGroupList(params) {
  return request(`${USER}/tenantMessageGroup/getMessageGroupList`, METHOD.GET, params)
}

// 删除租户消息配置
export async function deleteMessageConfig(params) {
  return request(`${USER}/tenantMessageConfig/deleteMessageConfig`, METHOD.POST, params)
}

// 获取消息配置的详细信息（点击编辑时使用）
export async function getMessageConfigInfo(params) {
  return request(`${USER}/tenantMessageConfig/getMessageConfigInfo`, METHOD.GET, params)
}

// 新增租户消息配置
export async function createMessageConfig(params) {
  return request(`${USER}/tenantMessageConfig/createMessageConfig`, METHOD.POST, params)
}

// 编辑租户消息配置
export async function updateMessageConfig(params) {
  return request(`${USER}/tenantMessageConfig/updateMessageConfig`, METHOD.POST, params)
}

// 更新租户消息分组状态
export async function changeGroupStatus(params) {
  return request(`${USER}/tenantMessageGroup/changeGroupStatus`, METHOD.POST, params)
}

// 获取短信模板集合
export async function getTemplateList(params) {
  return request(`${MESSAGE_SETTING}/notification/getTemplateList`, METHOD.POST, params)
}

// 更新消息配置状态
export async function changeMessageStatus(params) {
  return request(`${USER}/tenantMessageConfig/changeMessageStatus`, METHOD.POST, params)
}
// 更新消息配置状态
export async function getUserList(params) {
  return request(`${LOGIN}/user/getUserList`, METHOD.GET, params)
}
// 二维码跳转小程序 预览入职登记表
export async function getEntryFormPreviewQrCode(params) {
  return request(`${USER}/qrCode/getEntryFormPreviewQrCode`, METHOD.GET, params)
}
